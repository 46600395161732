import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  // [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  // [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  // [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  // [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  // [ChainId.GANACHE]: '0xad34a1e01f26250894FC463e68De7ffcE83DE2aF',
  [ChainId.GENEVA]: '0x88Ac471C3C020A5C3bD16Ec6756eeD81dc2C8E54',
  [ChainId.HARDHAT]: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
  [ChainId.WANNSEEMAINNET]: '0xbf5c081C000a02c07BC9a6141a7bfC6E85259946'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
