export const locales: any = {
  en: 'English',
  de: 'Deutsch',
  nl: 'Nederlands',
  tr: 'Türkçe',
  zh_CN: '简体中文',
  zh_TW: '繁体中文',
  ko: '한국어',
  ro: 'Română',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  ja: '日本語',
  ru: 'Русский',
  pt: 'Portugués',
  id: 'Indonesio',
  vi: 'Tiếng Việt'
}

export const browserLangToLocaleKey = (lang: any) => {
  const mapping: any = {
    en: 'en',
    'en-US': 'en',
    'en-GB': 'en',
    de: 'de',
    'de-DE': 'de',
    nl: 'nl',
    'nl-NL': 'nl',
    tr: 'tr',
    'tr-TR': 'tr',
    'zh-CN': 'zh_CN',
    'zh-TW': 'zh_TW',
    ko: 'ko',
    'ko-KR': 'ko',
    ro: 'ro',
    'ro-RO': 'ro',
    es: 'es',
    'es-ES': 'es',
    fr: 'fr',
    'fr-FR': 'fr',
    it: 'it',
    'it-IT': 'it',
    ja: 'ja',
    'ja-JP': 'ja',
    ru: 'ru',
    'ru-RU': 'ru',
    pt: 'pt',
    'pt-PT': 'pt',
    'pt-BR': 'pt',
    id: 'id',
    'id-ID': 'id',
    vi: 'vi',
    'vi-VN': 'vi'
  }

  return mapping[lang] || null
}
